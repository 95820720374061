import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import moment from 'moment'

function slugify(text) {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  const to = "aaaaaeeeeeiiiiooooouuuunc------"

  const newText = text.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/&/g, '-y-')           // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}


const NewsPreview = ({posts})=> {
    let activePosts = posts.filter((post) => {
        return post.expiresAt === null || moment(post.expiresAt).isAfter(moment());
    });
    const featureActivePosts = activePosts.sort((a,b) => {
        if(a.featured === true && b.featured === false) {
            return -1
        } else if(a.featured === false && b.featured === true) {
            return 1;
        } else {
            return 0;
        }
    })
    return (
        <div className="news-preview">
            {featureActivePosts.slice(0,6).map(post => {
                const date = moment(post.publishedAt)
                const endslug = slugify(post.title)
                const slug = `/posts/${date.format("YYYY-MM-DD")}/${endslug}`
                return (
                    <Link to={slug} className="news-card">
                        <div className="news-image">
                            <Img className="image" fluid={post.heroImage.documentFile.childImageSharp.fluid} />
                        </div>
                        <div className="news-card-body">
                            <small>{date.format("MMM D, YYYY")}</small>
                            <h3 className="title is-size-4">{post.title}</h3>
                            <p className="">{post.description}</p>

                        </div>
                        <div className="news-card-footer">
                            <Link to={slug} className="button is-primary">READ MORE</Link>
                        </div>
                    </Link>

                )})}
            

        </div>

    )
}

export default NewsPreview